import { DefaultError } from './DefaultError';
import { CustomError } from './CustomError';
import { analytics, AppError } from 'merchant-components';
import { ErrorComponentProps } from '@tanstack/react-router';

export const ErrorPage = (props: ErrorComponentProps) => {
  analytics.track('DetailedError', {...props.error, ComponentStack: props.info?.componentStack});
  if (props.error === AppError.NoOffer) return <CustomError/>
  return <DefaultError />;
};


export default ErrorPage;