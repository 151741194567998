import React, { memo } from 'react';
import { FormSelect } from 'react-bootstrap';

interface SelectProps {
  data: Array<any>;
  disabled?: boolean;
  onChange: (e: any, d: any) => undefined;
  value?: any;
}

const Select = ({ data, onChange, ...props }: SelectProps) => {
  const handleOnChange = (e: any) => {
    onChange(e.target.value, e);
  };

  return (
    <FormSelect onChange={handleOnChange} {...props}>
      {data.map((item) => (
        <option key={item.id} value={item.id} disabled={item.disabled}>
          {item.name}
        </option>
      ))}
    </FormSelect>
  );
};

Select.defaultProps = {
  data: [],
  disabled: false,
  value: '',
  onChange: () => undefined,
};

export default memo(Select);
export { Select };
