import React from 'react';
import { BlueBlob, Button} from '@odmonorepo/core-components';
import { Card, Stack } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface CustomErrorProps {
  title?: React.ReactNode;
  message?: React.ReactNode;
  link?: string;
  CTA?: string;
}

const DefaultTitle = () => <FormattedMessage id="customErrorMessage.title" defaultMessage="We found an issue with your account" />
const DefaultMessage = () => <FormattedMessage id="customErrorMessage.body" values={{tel: (txt: any) => <a href={`tel:${txt}`} className="fw-bold">{txt}</a>}}/>

export const CustomError = ({title = <DefaultTitle />, message = <DefaultMessage />, link = 'tel:(888)556-3483', CTA = 'Call Customer Service' }: CustomErrorProps) => (
  <BlueBlob style={{ marginTop: '60px'}}>
    <div className="h3 mb-5 mx-3">{title}</div>
    <Stack gap={5} className="mx-3">
      <Card className="mx-auto pt-4 pb-4 px-4" >
        <div className="px-4 py-4 text-start">{message}</div>
        <Button type="button" className="mx-auto mb-3 p-4d" href={link}>{CTA}</Button>
      </Card>
    </Stack>
  </BlueBlob>
);


export default CustomError;