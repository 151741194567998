import React from "react";
import { Form, FormCheckProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { getError } from "./FormHelpers";
import { useWizardFormContext } from "@odmonorepo/core-components";

interface FormCheckboxProps extends FormCheckProps {
  name: string;
  label?: any;
  useYupErrors?: boolean;
} 

export const FormCheckbox = ({ name, label, useYupErrors = false, ...props }: FormCheckboxProps) => {
  const { register, clearErrors } = useFormContext() || { register: () => {}, formState: {} as any };
  const onInput = () => clearErrors(name);
  const { formName } = useWizardFormContext();

  return <Form.Check
    className="fs-7 pt-1"
    id={name}
    label={label ? label : <FormattedMessage id={`formCheckbox.${name}`} />}
    onInput={onInput}
    isInvalid={!!getError(name)}
    feedback={useYupErrors ? getError(name)?.message : <FormattedMessage id='form.checkbox.error'/>}
    feedbackType="invalid"
    form={formName || ''}
    {...register(name as any) || {}}
    {...props}
  />
};

export default FormCheckbox;
