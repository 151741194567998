import { combineClassNames } from '@odmonorepo/odfunctions';
import React from 'react';
import { Tooltip as BTooltip, OverlayTrigger, TooltipProps as BTooltipProps } from 'react-bootstrap';
import TooltipIcon from './Tooltip.svg';

type tooltipPlacements = 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' |
	'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'

type tooltipVariant = 'standard' | 'definition'

interface TooltipProps extends BTooltipProps {
	children?: React.ReactNode,
	title?: string,
	flip?: boolean,
	placement?: tooltipPlacements,
	variant?: tooltipVariant,
}

const Tooltip = ({ children, title, className = 'tooltip-container', variant = 'standard', ...props }: TooltipProps) => {
	return (
		<OverlayTrigger
			overlay={
				<BTooltip>
					{title || children}
				</BTooltip>
			}
			{...props}
		>
			{variant === 'standard' ?
				<img src={TooltipIcon} style={{ marginLeft: '.5rem', width: '1rem', display: 'inline-block'}} className={className} data-testid="Tooltip"/>
:
				<a data-testid="Tooltip" className={combineClassNames("tooltip-toggle", className)}>{children}</a>
			}
		</OverlayTrigger>
	);
};

export default Tooltip;
export { Tooltip, tooltipPlacements };