export const randomString = (int: number): string => Array.from(Array(int), () => Math.floor(Math.random() * 36).toString(36)).join('');
export const combineClassNames = (...args: (string | undefined)[]) => [...args].join(' ').trim()
export const pluck = (objs, property) => objs.map((obj) => obj[property]);
export const findObjWithType = (obj: any, type: string) => obj?.find(o => o.type === type);
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const formatLastFour = (number: string) => '(...' + number?.slice(-4) + ')';
export const formatDate = (dateString) => new Date(dateString).toLocaleDateString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric'  });
export const dollarFormat = (amount: number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
export const stringifyCache = (cache) => JSON.stringify(Array.from(cache.keys()).reduce((obj: any, key) => ({ ...obj, [String(key)]: cache.get(key) }), {}));
export const mapStringCache = (stringCache) => new Map(Object.entries(JSON.parse(stringCache)));
export const makeOptions = (name, length): string[] => Array.from({ length: length }, (_, i) => `${name}.${i + 1}`)
