import React, { useEffect, useState } from 'react';
import { getTrackBackground, Range as RRange } from 'react-range';
import { FormattedNumber } from 'react-intl'

/* eslint-disable react/prop-types */
export const renderThumb = ({ props }: any) => (
  <div
    {...props}
    style={{
      ...props.style,
      height: '29px',
      width: '29px',
      borderRadius: '50%',
      border: '2px solid #E7E7F4',
      backgroundColor: '#006BEB'
    }}
    data-testid="thumb"
  />
);

export const RenderTrack = ({ props, children, sliderValues, min, max }
  : {
    props: any,
    children: React.ReactNode,
    sliderValues: Array<number>,
    min: number,
    max: number
  }) => {
  return (
    <div>
      <div
        aria-hidden="true"
        onMouseDown={props.onMouseDown}
        onTouchStart={props.onTouchStart}
        style={{
          ...props.style,
          height: '36px',
          display: 'flex',
          width: '100%'
        }}
      >
        <div
          ref={props.ref}
          style={{
            height: '19px',
            width: '100%',
            borderRadius: '11px',
            background: getTrackBackground({
              values: sliderValues,
              colors: ['#000082', '#DDDDDD'],
              min: min,
              max: max
            }),
            alignSelf: 'center',
            border: '4px solid rgb(221,221,221)'
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

type RangeProps = JSX.IntrinsicElements['div'] & {
  min?: number;
  max?: number;
  value: number;
  showMinMaxLabel?: boolean;
  showCents?: boolean;
  thumb?: boolean;
  label?: boolean;
  step?: number;
  percentage?: number;
  onChange?: (e: any) => void;
}

export const Range = ({ min = 0, max = 100, value = 100, step=1000, showMinMaxLabel = false, thumb, label, onChange = (e) => undefined, percentage, ...props }: RangeProps) => {
  const [sliderValues, setSliderValues] = useState([min]);

  useEffect(() => {
    if (value >= min && value <= max) setSliderValues([value]);
  }, [value, max, min]);

  const calcPercentage = percentage ? percentage : ((sliderValues[0] - min) / (max - min) * 100);
  return (
    <div className={props.className}>
      {showMinMaxLabel && (
        <div className='d-flex justify-content-between'>
          <div>Min: <FormattedNumber value={min} style="currency" currency="USD" maximumFractionDigits={0} minimumFractionDigits={0} /></div>
          <div>Max: <FormattedNumber value={max} style="currency" currency="USD" maximumFractionDigits={0} minimumFractionDigits={0} /></div>
        </div>
      )}
      {label && min <= sliderValues[0] && max >= sliderValues[0] && (
        <label className="text-center position-relative rounded-pill fw-bold text-bg-secondary fs-6 p-1"
          style={{ left: calcPercentage + '%', transform: 'translateX(-50%)', minWidth: '60px' }}>
          {`${Math.floor(calcPercentage)}%`}
        </label>
      )}
      <RRange
        step={step}
        min={min}
        max={max}
        values={sliderValues}
        onChange={v => {
          setSliderValues(v);
          onChange(v[0]);
        }}
        disabled={!thumb}
        renderTrack={({ props, children }) => (
          <RenderTrack props={props} sliderValues={sliderValues} min={min} max={max}>
            {children}
          </RenderTrack>
        )}
        renderThumb={thumb ? renderThumb : () => ''}
      />
    </div>
  );
};
