import React, { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal, ReactNode } from 'react'
import { FormatNumberOptions, FormattedMessage, FormattedNumber, MessageDescriptor } from 'react-intl';


interface DivExtenderProps {
  className?: string;
  values?: any;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

interface NumberFormatOptions extends FormatNumberOptions {
  value: number;
}

export const FormattedMessageDiv = ({ className, id, values, ...props }: MessageDescriptor & DivExtenderProps) =>
  <div className={className}>{props?.prefix}<FormattedMessage id={id} values={values} {...props as MessageDescriptor} />{props?.suffix}</ div>

export const FormattedNumberDiv = ({ className, value, style, currency, ...props }: NumberFormatOptions & DivExtenderProps) =>
  <div className={className}>{props?.prefix}<FormattedNumber value={value} style={style} currency={currency} {...props as FormatNumberOptions} />{props?.suffix}</ div>