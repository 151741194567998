import React, { useEffect, useState } from 'react';
import { Card as BCard, CardProps as BCardProps } from 'react-bootstrap';

const { Body } = BCard;

type cardBgs = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'

type cardTexts = cardBgs | 'white' | 'muted';

export interface CardProps extends BCardProps {
  width?: string;
  height?: string;
  bg?: string;
  border?: cardBgs;
  text?: cardTexts;
  children?: React.ReactNode;
  bodyClassName?: string;
  dismissible?: boolean;
  dismissItem?: string;
}
export const Card = ({
  width,
  height,
  children,
  bodyClassName,
  dismissible,
  dismissItem,
  ...props
}: CardProps) => {
  const [showCard, setShowCard] = useState(true);

  useEffect(() => {
    const storedDismissed = dismissItem && sessionStorage.getItem(dismissItem);
    storedDismissed && setShowCard(false);
  }, [dismissItem]);

  return showCard ? (
    <BCard style={{ width, height }} {...props}>
      {dismissible && (
        <button
          role="button"
          className="btn-close position-absolute end-0 me-4 mt-4"
          onClick={() => {
            setShowCard(false);
            dismissItem && sessionStorage.setItem(dismissItem, 'notificationDismiss');
          }}
        />
      )}
      <Body className={bodyClassName}>{children}</Body>
    </BCard>
  ) : (
    <div></div>
  );
};

export default Card;
