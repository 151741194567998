import { Button, Stack } from 'react-bootstrap';
import {
  ErrorPage,
  FormattedMessageDiv,
  LoadingAnimation,
} from '@odmonorepo/core-components';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CalculatorInputSlider } from '../Calculator/CalculatorInputSlider';
import { CalculatorPaymentFrequency } from '../Calculator/CalculatorPaymentFrequency';
import { CalculatorTermSelection } from '../Calculator/CalculatorTermSelection';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { getPricingDetails, useDetailedOffers } from 'merchant-api/offer';

export const FullConfig = () => {
  const { setValue, watch } = useFormContext();
  const { tlOffers, isLoadingDetailedOffers, isErrorOffers } = useDetailedOffers();
  const { getPricingError } = getPricingDetails();

  useEffect(() => {
    setValue('showOfferPreview', false);
  }, []);

  if (isLoadingDetailedOffers)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto" />
      </Stack>
    );

  if (isErrorOffers || getPricingError)
    return (
      <Stack>
        <ErrorPage />
      </Stack>
    );

  return (
    <Stack className={combineClassNames("shadow-none px-3 pt-5 pb-sm-5 ps-sm-6", watch("showOfferPreview") ? "pe-sm-4" : "pe-sm-6")}>
        <FormattedMessageDiv
          className="fw-bold mb-4"
          id="offerConfig.full.calculator.heading"
        />
        <Stack gap={1}>
          <CalculatorInputSlider tlOffers={tlOffers} />
          <hr className="border-medium-gray" />
          <CalculatorTermSelection tlOffers={tlOffers} />
          <hr className="border-medium-gray" />
          <CalculatorPaymentFrequency />
          <hr className="border-medium-gray" />
          <Button
            className="w-100"
            onClick={() => {
              setValue('showOfferPreview', true);
            }}
          >
            <FormattedMessage id="calculator.calculate" />
          </Button>
        </Stack>
    </Stack>
  );
};
