import { Stack, Row, Col, Collapse } from 'react-bootstrap';
import {
  Card,
  FormInput,
  FormRadio,
  FormSelect,
  FormattedMessageDiv,
} from '@odmonorepo/core-components';
import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import { makeOptions } from '@odmonorepo/odfunctions';

export const BusinessContactSchema = yup.object().shape({
  business: yup.object().shape({
    legalName: yup.string().required(),
    address: yup.object().shape({
      lineOne: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().required(),
      postalCode: yup.string().required(),
    }),
    phone: yup.string().required(),
    dba: yup.boolean(),
    dbaName: yup
      .string()
      .when('dba', { is: true, then: (schema) => schema.required() }),
  }),
});

export const BusinessContact = () => {
  const { watch } = useFormContext();
  const watchDba = watch('business.dba') === 'true';

  return (
    <Card>
      <Stack gap={3}>
        <FormattedMessageDiv
          className="fw-bold"
          id={'renewalApp.BusinessInfo'}
        />
        <FormInput name="business.legalName" disabled helperText/>
        <FormRadio name="business.dba" options={['true', 'false']} />
        <Collapse in={watchDba}>
          <FormInput name="business.dbaName" />
        </Collapse>
        <FormInput name="business.address.lineOne" />
        <Row xs={1} md={3} className="g-3">
          <Col>
            <FormInput name="business.address.city" />
          </Col>
          <Col>
            <FormSelect
              name="business.address.state"
              options={makeOptions('business.address.state', 51)}
            />
          </Col>
          <Col>
            <FormInput name="business.address.postalCode" />
          </Col>
        </Row>
        <FormInput name="business.phone" type="tel" />
      </Stack>
    </Card>
  );
};

export default BusinessContact;
