import React, { ChangeEvent, useState, useRef } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { BsPencilSquare, BsUpload} from "react-icons/bs";

interface FileUploadProps extends React.ComponentProps<typeof Form.Control> {
  label?: string;
  placeholder?: string;
  error?: string;
}

const FileUpload = ({
  label = 'Choose file',
  placeholder = 'Maximum file size: 5MB',
  error,
  isInvalid,
  name = 'fileUpload',
  onChange = () => {},
  ...props
}: FileUploadProps, ref) => {
  const [fileName, setFileName] = useState<string>();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => (setFileName(event.target.files?.[0]?.name), onChange(event));
  const handleCardClick = () => document.getElementById(name)?.click();

  return (
    <Form.Group controlId={name} className="mb-3">
      <Form.Control type="file" hidden onChange={handleFileChange} multiple={false} ref={ref} name={name} {...props}/>
        <Stack
          tabIndex={0}
          role="button"
          onClick={handleCardClick}
          onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && handleCardClick()}
          direction="horizontal"
          className={`p-4 bg-light-gray rounded-3 border ${isInvalid ? 'border-danger' : 'border-light-gray'}`}
        >
          <Stack className='me-auto'>
            <Form.Label style={{ cursor: 'pointer' }} className="fw-bold m-0" onClick={e => e.stopPropagation()}>{label}</Form.Label>
            <Form.Text className="text-muted m-0">{fileName || placeholder}</Form.Text>
          </Stack>
          {fileName ? <BsPencilSquare size={20}/> : <BsUpload size={20}/>}
        </Stack>
        {error && <div className="text-danger fs-8 mt-1">{error}</div>}
    </Form.Group>
  );
};

export default React.forwardRef(FileUpload);