import { FormattedMessageDiv, getError, useWizardFormContext } from "@odmonorepo/core-components";
import { Form, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export interface FormRadioProps {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  gap?: number;
  options?: string[];
  type?: "radio" | "checkbox";
  label?: string | null;
  useYupErrors?: boolean;
}

export const FormRadio = ({name = '', label, type = "radio", required = true, inline = true, disabled = false, useYupErrors = false, gap, options = [], ...props}: FormRadioProps) => {
  const { register, formState, clearErrors } = useFormContext() || {register: () => {}, formState: {} as any};
  const onInput = (e: any) => e.target.value && clearErrors(name)
  const { formName } = useWizardFormContext();

  return (
    <Stack gap={2}>
      {label !== undefined ? label : <FormattedMessageDiv className="fs-7" id={'formRadio.' + name + '.label'}/>}
      <div>
        <Stack gap={gap ? gap : (inline ? 5 : 3)} direction={inline ? 'horizontal' : 'vertical'}>
          {options.map(o => (
            <Form.Check
              type={type}
              id={name + o}
              value={o}
              key={name + o}
              label={<FormattedMessageDiv className="fs-7" id={'formRadio.' + name + ".option." + o}/>}
              required={required}
              onInput={onInput}
              disabled={disabled}
              {...register(name as any)}
              isInvalid={!!getError(name)}
              form={formName || ''}
            />
          ))}
        </Stack>
        {getError(name) && <div className="text-danger fs-8">{useYupErrors ? getError(name)?.message : <FormattedMessageDiv id='form.selection.error'/>}</div>}
       </div>
    </Stack>
  );
}

export default FormRadio;

export const FormCheckboxes = (props: FormRadioProps) => <FormRadio type="checkbox" {...props}/>
