import React, { useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { ToggleElement } from "@odmonorepo/core-components";
import { PPDOption } from './PPDOption';
import { Button, FormattedMessageDiv } from '@odmonorepo/core-components';
import { useWizard } from 'react-use-wizard';
import { useNavigate } from '@tanstack/react-router';
import { OfferConfigStep } from 'merchant-components';
import { useDetailedOffers } from 'merchant-api/offer';

export const PPDOptions = () => {
  const { watch } = useFormContext();
  const { goToStep } = useWizard();
  const { tlOffers } = useDetailedOffers();
  const navigate = useNavigate();

  const exampleInterest = 1000;

  const clickedOffer = tlOffers.filter(
    (o) => o.offerId === watch('offer.offerId'),
  )[0];

  useEffect(() => {
    if(clickedOffer.prePaymentDiscountRelatedOfferIds === null || Object.keys(clickedOffer.prePaymentDiscountRelatedOfferIds).length === 0) {
       // populate form data to backend getDetailedOffers "selectedOffer"
      navigate({to: '/checkout'});
    }
  }, []);

  return (
    <Stack className="shadow-none w-100 px-4 pt-4 pt-md-5 pe-md-2 ps-md-6">
      <Controller
        name="offer.ppdDiscount"
        render={({ field: { onChange, name, value } }) => (
          <Stack direction="vertical" gap={3}>
            {['100', '25'].map((discount) => (
              <ToggleElement value={discount} onChange={onChange} name={name}>
                <PPDOption
                  discount={discount}
                  exampleInterest={exampleInterest}
                  selected={value === discount || watch(name) === discount}
                />
              </ToggleElement>
            ))}
          </Stack>
        )}
      />
      <Button
        variant="link"
        onClick={() => {
          goToStep(OfferConfigStep.SIMPLE_CONFIG);
        }}
      >
        <FormattedMessageDiv
          className="fs-7 m-2"
          id="prepayment.link.selectDifferentOffer"
        />
      </Button>
    </Stack>
  );
};

export default PPDOptions;
