import { combineClassNames } from '@odmonorepo/odfunctions';
import BlueBackground from './Background_Blob-Blue.svg';

type BlueBlobProps = JSX.IntrinsicElements['div'] & {
  children?: React.ReactNode;
};

export const BlueBlob = ({ children = null, ...props }: BlueBlobProps) => (
  <div
    {...props}
    style={{ paddingTop: 'min(20vw, 70px)', ...props.style }}
    className={combineClassNames(
      'text-center position-relative',
      props.className
    )}
  >
    <img
      src={BlueBackground}
      className="position-absolute top-0 start-50 translate-middle-x z-n1 overflow-hidden"
      style={{ maxWidth: '575px', width: '100%' }}
    />
    {children}
  </div>
);

export default BlueBlob;
