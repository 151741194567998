import { createRouter } from '@tanstack/react-router'
// Import the generated route tree
import { routeTree } from './routeTree.gen'
import type { RouteIds } from '@tanstack/react-router'
import { LoadingAnimation } from '@odmonorepo/core-components'
import { Stack } from 'react-bootstrap'

// Set up a Router instance
export const router = createRouter({
  routeTree,
  defaultPendingComponent: () => (
   <Stack><LoadingAnimation className='m-auto'/></Stack>
  ),
})

export type RouterType = typeof router
export type RouterIds = RouteIds<RouterType['routeTree']>
