import { useApplication } from 'merchant-api/butterfly';
import useSWR, {useSWRConfig} from 'swr';
import useSWRMutation from "swr/mutation";

export interface uploadDocumentsProps {
  response: any;
  documentsSending: boolean;
  uploadError: string;
  upload: (files:FormData) => void;
}

export function uploadDocuments(): uploadDocumentsProps {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL']
  const { fetcher } = useSWRConfig();

  async function sendRequest(url, { arg }:{arg: FormData}) {
    return fetcher && fetcher(url, {method: 'POST', body: arg, unsetContentType: true});
  };

	const {opportunityId, partyId, applicationId} = useApplication();
	const urlParams = `opportunityId=${opportunityId}&partyId=${partyId}&applicationId=${applicationId}`;
  const { data, error, trigger, isMutating } = useSWRMutation(`${apiUrl}/v1/documents?${urlParams}`, sendRequest);
  return {
    response: data,
    documentsSending: isMutating,
    uploadError: error,
    upload: trigger
  }
}

export default uploadDocuments;
