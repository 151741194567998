import { FloatingLabel, FloatingLabelProps, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { getError } from './FormHelpers';
import { useWizardFormContext } from '@odmonorepo/core-components';

interface FormSelectProps extends Omit<FloatingLabelProps, 'label'> {
  name?: string;
  options?: string[] | number[] | object[];
  label?: React.ReactNode;
  showLabel?: boolean;
  blankLabel?: string;
  required?: boolean;
  disabled?: boolean;
  useYupErrors?: boolean;
  useValueAsDisplay?: boolean;
}

export const FormSelect = ({ name = '', required = true, disabled = false, options, useYupErrors = false, showLabel = true, blankLabel = "", label = "", useValueAsDisplay = false, ...props }) => {
  const { register, formState, clearErrors } = useFormContext() || { register: () => { }, formState: {} as any };
  const onInput = (e) => e.target.value && clearErrors(name);
  const { formatMessage } = useIntl();
  const { formName } = useWizardFormContext();

  const selectElement = (
    <Form.Select
      required={required}
      onInput={onInput}
      disabled={disabled}
      {...register(name as any)}
      isInvalid={!!getError(name)}
      form={formName || ''}
      { ...props}
    >
      {blankLabel && <option value="">{blankLabel}</option>}
      {options.map((o) => (
        typeof o === 'object' && useValueAsDisplay ? (
          <option key={name + o.value} value={o.value}>{o.label}</option>
        ) : (
        <option value={useValueAsDisplay ? o : formatMessage({ id: 'formSelect.option.' + o + '.value', defaultMessage: formatMessage({ id: 'formSelect.option.' + o }) })} key={name + o}>
          {useValueAsDisplay ? o : <FormattedMessage id={'formSelect.option.' + o} />}
        </option>
        )
      ))}
    </Form.Select>
  );

  return showLabel ? (
    <FloatingLabel controlId={name} {...props} label={label ? label : <FormattedMessage id={'formSelect.' + name} />}>
      {selectElement}
      <Form.Control.Feedback type="invalid">{useYupErrors ? getError(name)?.message : <FormattedMessage id='form.selection.error' />}</Form.Control.Feedback>
    </FloatingLabel>
  ) : (
    <>
      {selectElement}
      <Form.Control.Feedback type="invalid">{useYupErrors ? getError(name)?.message : <FormattedMessage id='form.selection.error' />}</Form.Control.Feedback>
    </>
  );
};

export default FormSelect;
