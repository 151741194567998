import { Stack, Row, Col, Badge, Card } from 'react-bootstrap';
import {
  FormattedMessageDiv,
  FormattedNumberDiv,
} from '@odmonorepo/core-components';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { Offer } from 'merchant-api/types';

interface SelectOfferProps {
  offer: Offer;
  selected?: boolean;
}

export const SelectOfferCard = ({ offer, selected }: SelectOfferProps) => (
  <Card
    className={combineClassNames(
      'shadow-none p-3 pt-4 border',
      selected ? 'border-primary' : '',
    )}
    bg={selected ? 'light-gray' : 'white'}
    role="button"
  >
    <Stack gap={4} direction="horizontal">
      <input
        type="radio"
        checked={selected}
        style={{ pointerEvents: 'none' }}
        className="mt-3 me-4 align-self-start"
      />
      <Stack>
        <Row>
          <Col className='p-1'>
            <FormattedMessageDiv
              id="offerPreview.loanAmount"
              className="small fw-bold"
            />
            <FormattedNumberDiv
              value={offer.pricedDetails.lineAmount}
              style="currency"
              currency="USD"
              className="fs-4"
              maximumFractionDigits={0}
            />
          </Col>
          <Col className='p-1'>
            <FormattedMessageDiv
              id="selectOffer.term"
              className="small fw-bold"
            />
            <FormattedNumberDiv
              value={offer.term}
              suffix=" months"
              className="fs-4"
            />
          </Col>
          <Col className='p-1'>
            <FormattedMessageDiv
              id="selectOffer.weeklyPayment"
              className="small fw-bold"
            />
            <FormattedNumberDiv
              value={offer.pricedDetails.payment}
              style="currency"
              currency="USD"
              className="fs-4"
            />
          </Col>
        </Row>
        <Row>
          {Object.keys(offer.prePaymentDiscountRelatedOfferIds).length > 0 ? (
            <div>
              <Badge bg="success">
                <FormattedMessageDiv id="prepayment.select.100.title" />
              </Badge>
            </div>
          ) : (
            <div className="mt-1 fs-8">
              <FormattedMessageDiv id="prepayment.select.25.title" />
            </div>
          )}
        </Row>
      </Stack>
    </Stack>
  </Card>
);

export default SelectOfferCard;
