import { Col, Row } from 'react-bootstrap';
import OfferPreview from '../Components/OfferPreview/OfferPreview';
import { useFormContext } from 'react-hook-form';
import { Card } from '@odmonorepo/core-components';

export const ConfigStepCard = ({ children }) => {
  const { watch } = useFormContext();
  const showOfferPreview = watch('showOfferPreview') != null ? watch('showOfferPreview') : true;
  return (
    <Card bodyClassName='p-0'>
        <Row sm={1} md={ showOfferPreview ? 2 : 1}>
          <Col>{children}</Col>
          { showOfferPreview && (
            <Col>
              <OfferPreview />
            </Col>
          )}
        </Row>
    </Card>
  );
};
