import React from 'react';
import { Button, Page500 } from '@odmonorepo/core-components';

export const DefaultError = () => {
  return (
    <div className="p-4 d-flex flex-column container align-items-center">
      <Page500 />
      <h2 className="mt-5 fw-normal text-dark text-center">
        We're having some technical trouble
      </h2>
      <p className="m-3 text-center col-xl-8">
        Please try refreshing the page. If that doesn’t work, please send us an
        email at{' '}
        <a className="fw-normal" href="mailto:customerservice@ondeck.com">
          customerservice@ondeck.com
        </a>{' '}
        or call us at{' '}
        <a className="fw-normal" href="tel:+18885563483">
          <span>1 (888) 556-3483</span>
        </a>{' '}
        between 9:00 a.m. - 6:00 p.m. EST. We appreciate your patience and
        apologize for the inconvenience.
      </p>
      <Button href="/" className="m-6">
        Return Home
      </Button>
    </div>
  );
};

export default DefaultError; 