import { FloatingLabelProps, FloatingLabel, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { getError } from './FormHelpers';
import { useWizardFormContext } from '@odmonorepo/core-components';

interface FormInputProps extends Omit<FloatingLabelProps, 'label'> {
  name?: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  currency?: string;
  useYupErrors?: boolean;
  allowCents?: boolean;
}
export const FormMoneyInput = ({name = '', required = true, type = 'number', currency = '$', disabled = false, useYupErrors = false, allowCents = false, ...props}: FormInputProps) => {
  const { register, watch, clearErrors } = useFormContext() || { register: () => {}, formState: {} as any};
  const onInput = (e: any) => e.target.value && clearErrors(name);
  const { formName } = useWizardFormContext();

  return (
    <FloatingLabel controlId={name} {...props} label={<FormattedMessage id={'formInput.' + name} />}>
      <span className={`currency-symbol ${disabled && 'text-disabled'}`}>{currency}</span>
      <Form.Control
        style={{ paddingLeft: '1.125rem' }}
        type={type}
        onInput={onInput}
        required={required}
        disabled={disabled}
        {...register(name)}
        isInvalid={!!getError(name)}
        form={formName || ''}
        onKeyDown={e => (!['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)) && (allowCents ? !/^(\d+\.?\d{0,2}|\.\d{0,2})$/.test(watch(name) + e.key) : !/[0-9]/.test(e.key)) && e.preventDefault()}
      />
      <style>{`.currency-symbol { position: absolute; left: .5rem; top: 1.55rem;`}</style>
      <Form.Control.Feedback type="invalid">{useYupErrors ? getError(name)?.message : <FormattedMessage id={'formInput.' + name + '.error'}/>}</Form.Control.Feedback>
    </FloatingLabel>
  );
};

export default FormMoneyInput;
