import React from 'react';
import { Button as BButton, ButtonProps as BButtonProps, Spinner, SpinnerProps } from 'react-bootstrap';
import './Button.css';

type buttonVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' |
  'light' | 'link' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-dark' | 'outline-light'


const variantsToDisplay = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LIGHT: 'light',
  LINK: 'link',
  OUTLINE_PRIMARY: 'outline-primary',
  OUTLINE_SECONDARY: 'outline-secondary',
  OUTLINE_LIGHT: 'outline-light',
};

const buttonSize = {
  Small: 'sm',
  Default: '',
  Large: 'lg',
};
type ButtonProps = BButtonProps & {
  loading?: boolean;
  loadingText?: string;
  children?: React.ReactNode;
  spinnerProps?: SpinnerProps;
  active?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  size?: 'sm' | 'lg';
  variant?: buttonVariant;
  className?: string;
}

const Button = ({ loading, loadingText = 'Loading', children, spinnerProps = {size: 'sm', animation: 'border'}, ...props }: ButtonProps) => {
  return (
    <BButton {...props}>
      {loading ? (
        <span className="btn-loading-container">
          <Spinner {...spinnerProps as any} />
          <span className="btn-loading-text">{loadingText}</span>
        </span>
      ) : (
        children
      )}
    </BButton>
  );
};


export default Button;
export { Button, buttonSize, variantsToDisplay };
