import { Stack, Row, Col } from 'react-bootstrap';
import {
  Card,
  FormInput,
  FormSelect,
  FormattedMessageDiv,
} from '@odmonorepo/core-components';
import * as yup from 'yup';
import { makeOptions } from '@odmonorepo/odfunctions';

export const OwnerContactSchema = yup.object().shape({
  person: yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    address: yup.object().shape({
      lineOne: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().required(),
      postalCode: yup.string().required(),
    }),
    homePhone: yup.string().required(),
  }),
});

export const OwnerContact = () => (
  <Card>
    <Stack gap={3}>
      <FormattedMessageDiv className="fw-bold" id={'renewalApp.ownerInfo'} />
      <FormInput name="person.firstName" />
      <FormInput name="person.lastName" />
      <FormInput name="person.address.lineOne" />
      <Row xs={1} md={3} className="g-3">
        <Col>
          <FormInput name="person.address.city" />
        </Col>
        <Col>
          <FormSelect
            name="person.address.state"
            options={makeOptions('business.address.state', 51)}
          />
        </Col>
        <Col>
          <FormInput name="person.address.postalCode" />
        </Col>
      </Row>
      <FormInput name="person.homePhone" type="tel" />
    </Stack>
  </Card>
);

export default OwnerContact;
