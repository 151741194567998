import { Form } from "react-bootstrap";
import React, { createContext, useContext, useState } from "react";
import { UseFormProps, useForm, FormProvider } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface WizardFormProps extends Partial<Omit<UseFormProps, 'resolver'>> {
  schema?: yup.ObjectSchema<any>;
  children: React.ReactNode;
  onSubmit?: React.DOMAttributes<HTMLFormElement>['onSubmit'];
  startIndex?: number;
  formName?: string;
}

interface WizardFormContextProps {
  triggerPartial: (newSchema?: yup.ObjectSchema<any>) => any;
  formName?: string;
}

const WizardFormContext = createContext<WizardFormContextProps>({
  triggerPartial: () => false,
  formName: ''
});

export const WizardForm = ({ children, schema = yup.object().shape({}), startIndex, onSubmit = () => {}, formName = Math.random().toString(36).substring(7), ...props }: WizardFormProps) => {
  const [tempSchema, setTempSchema] = useState<yup.ObjectSchema<any> | undefined>();
  const form = useForm({ resolver: yupResolver(tempSchema ? tempSchema : schema), ...props });
  const contextValue = {
    formName: formName,
    triggerPartial: async (newSchema?: yup.ObjectSchema<any>) => {
      setTempSchema(newSchema);
      await new Promise(resolve => setTimeout(resolve, 200));
      const valid = await form.trigger();
      setTempSchema(undefined);
      return valid;
    },
  };
  const handleStepChange = () => {
    form.clearErrors();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <WizardFormContext.Provider value={contextValue}>
      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)} id={formName}/>
        <Wizard startIndex={startIndex} onStepChange={() => handleStepChange() }>
            {children}
        </Wizard>
      </FormProvider>
    </WizardFormContext.Provider>

  );
};

export const useWizardFormContext = () => useContext(WizardFormContext);
export default WizardForm;
