import { RouterProvider } from '@tanstack/react-router';
import { fetcher } from 'merchant-api';
import { analytics, merchantPortalText } from 'merchant-components';
import { router } from 'merchant-routes';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { SWRConfig } from 'swr';

export const App = () => {
  useEffect(() => {analytics.page()})
  return (
    <SWRConfig value={{fetcher: async (url, args) => fetcher(url, { credentials: 'include', ...args })}}>
      <IntlProvider locale="en-us" messages={merchantPortalText}>
        <RouterProvider router={router} />
      </IntlProvider>
    </SWRConfig>
  )
}

export default App;
