import { useState } from "react";

const abortController = new AbortController();

export const fetchTimeout = (url, ms = 10000, { signal= abortController.signal, ...options } = {}) => {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, ...options });
  if (signal) signal.addEventListener("abort", () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};


export const useForceUpdate = () => { const [_, setValue] = useState(0); return () => setValue(value => value + 1)}


export const fetchExample= (url, example, ms = 10000, { signal= abortController.signal, ...options } = {}) => {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, headers: {'Content-Type': 'application/json', Prefer: `code=200, ${example ? 'example=' + example : ''}`}, ...options });
  if (signal) signal.addEventListener("abort", () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};
