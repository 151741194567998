/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as MerchantRouteImport } from './routes/_merchant/route'
import { Route as MerchantOfferRouteImport } from './routes/_merchant/offer/route'
import { Route as MerchantCheckoutRouteImport } from './routes/_merchant/checkout/route'
import { Route as MerchantPortalRouteImport } from './routes/_merchant/_portal/route'
import { Route as MerchantOfferIndexImport } from './routes/_merchant/offer/index'
import { Route as MerchantCheckoutIndexImport } from './routes/_merchant/checkout/index'
import { Route as MerchantPortalIndexImport } from './routes/_merchant/_portal/index'
import { Route as MerchantPortalRenewalImport } from './routes/_merchant/_portal/renewal'
import { Route as MerchantPortalProfileImport } from './routes/_merchant/_portal/profile'
import { Route as MerchantPortalPaymentImport } from './routes/_merchant/_portal/payment'
import { Route as MerchantPortalInstantfundingImport } from './routes/_merchant/_portal/instantfunding'
import { Route as MerchantPortalDrawfundsImport } from './routes/_merchant/_portal/drawfunds'
import { Route as MerchantPortalDocumentsImport } from './routes/_merchant/_portal/documents'
import { Route as MerchantPortalBusinessImport } from './routes/_merchant/_portal/business'

// Create/Update Routes

const MerchantRouteRoute = MerchantRouteImport.update({
  id: '/_merchant',
  getParentRoute: () => rootRoute,
} as any)

const MerchantOfferRouteRoute = MerchantOfferRouteImport.update({
  id: '/offer',
  path: '/offer',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const MerchantCheckoutRouteRoute = MerchantCheckoutRouteImport.update({
  id: '/checkout',
  path: '/checkout',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const MerchantPortalRouteRoute = MerchantPortalRouteImport.update({
  id: '/_portal',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const MerchantOfferIndexRoute = MerchantOfferIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantOfferRouteRoute,
} as any)

const MerchantCheckoutIndexRoute = MerchantCheckoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantCheckoutRouteRoute,
} as any)

const MerchantPortalIndexRoute = MerchantPortalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalRenewalRoute = MerchantPortalRenewalImport.update({
  id: '/renewal',
  path: '/renewal',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalProfileRoute = MerchantPortalProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalPaymentRoute = MerchantPortalPaymentImport.update({
  id: '/payment',
  path: '/payment',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalInstantfundingRoute =
  MerchantPortalInstantfundingImport.update({
    id: '/instantfunding',
    path: '/instantfunding',
    getParentRoute: () => MerchantPortalRouteRoute,
  } as any)

const MerchantPortalDrawfundsRoute = MerchantPortalDrawfundsImport.update({
  id: '/drawfunds',
  path: '/drawfunds',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalDocumentsRoute = MerchantPortalDocumentsImport.update({
  id: '/documents',
  path: '/documents',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalBusinessRoute = MerchantPortalBusinessImport.update({
  id: '/business',
  path: '/business',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_merchant': {
      id: '/_merchant'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MerchantRouteImport
      parentRoute: typeof rootRoute
    }
    '/_merchant/_portal': {
      id: '/_merchant/_portal'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MerchantPortalRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/checkout': {
      id: '/_merchant/checkout'
      path: '/checkout'
      fullPath: '/checkout'
      preLoaderRoute: typeof MerchantCheckoutRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/offer': {
      id: '/_merchant/offer'
      path: '/offer'
      fullPath: '/offer'
      preLoaderRoute: typeof MerchantOfferRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/_portal/business': {
      id: '/_merchant/_portal/business'
      path: '/business'
      fullPath: '/business'
      preLoaderRoute: typeof MerchantPortalBusinessImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/documents': {
      id: '/_merchant/_portal/documents'
      path: '/documents'
      fullPath: '/documents'
      preLoaderRoute: typeof MerchantPortalDocumentsImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/drawfunds': {
      id: '/_merchant/_portal/drawfunds'
      path: '/drawfunds'
      fullPath: '/drawfunds'
      preLoaderRoute: typeof MerchantPortalDrawfundsImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/instantfunding': {
      id: '/_merchant/_portal/instantfunding'
      path: '/instantfunding'
      fullPath: '/instantfunding'
      preLoaderRoute: typeof MerchantPortalInstantfundingImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/payment': {
      id: '/_merchant/_portal/payment'
      path: '/payment'
      fullPath: '/payment'
      preLoaderRoute: typeof MerchantPortalPaymentImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/profile': {
      id: '/_merchant/_portal/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof MerchantPortalProfileImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/renewal': {
      id: '/_merchant/_portal/renewal'
      path: '/renewal'
      fullPath: '/renewal'
      preLoaderRoute: typeof MerchantPortalRenewalImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/': {
      id: '/_merchant/_portal/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof MerchantPortalIndexImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/checkout/': {
      id: '/_merchant/checkout/'
      path: '/'
      fullPath: '/checkout/'
      preLoaderRoute: typeof MerchantCheckoutIndexImport
      parentRoute: typeof MerchantCheckoutRouteImport
    }
    '/_merchant/offer/': {
      id: '/_merchant/offer/'
      path: '/'
      fullPath: '/offer/'
      preLoaderRoute: typeof MerchantOfferIndexImport
      parentRoute: typeof MerchantOfferRouteImport
    }
  }
}

// Create and export the route tree

interface MerchantPortalRouteRouteChildren {
  MerchantPortalBusinessRoute: typeof MerchantPortalBusinessRoute
  MerchantPortalDocumentsRoute: typeof MerchantPortalDocumentsRoute
  MerchantPortalDrawfundsRoute: typeof MerchantPortalDrawfundsRoute
  MerchantPortalInstantfundingRoute: typeof MerchantPortalInstantfundingRoute
  MerchantPortalPaymentRoute: typeof MerchantPortalPaymentRoute
  MerchantPortalProfileRoute: typeof MerchantPortalProfileRoute
  MerchantPortalRenewalRoute: typeof MerchantPortalRenewalRoute
  MerchantPortalIndexRoute: typeof MerchantPortalIndexRoute
}

const MerchantPortalRouteRouteChildren: MerchantPortalRouteRouteChildren = {
  MerchantPortalBusinessRoute: MerchantPortalBusinessRoute,
  MerchantPortalDocumentsRoute: MerchantPortalDocumentsRoute,
  MerchantPortalDrawfundsRoute: MerchantPortalDrawfundsRoute,
  MerchantPortalInstantfundingRoute: MerchantPortalInstantfundingRoute,
  MerchantPortalPaymentRoute: MerchantPortalPaymentRoute,
  MerchantPortalProfileRoute: MerchantPortalProfileRoute,
  MerchantPortalRenewalRoute: MerchantPortalRenewalRoute,
  MerchantPortalIndexRoute: MerchantPortalIndexRoute,
}

const MerchantPortalRouteRouteWithChildren =
  MerchantPortalRouteRoute._addFileChildren(MerchantPortalRouteRouteChildren)

interface MerchantCheckoutRouteRouteChildren {
  MerchantCheckoutIndexRoute: typeof MerchantCheckoutIndexRoute
}

const MerchantCheckoutRouteRouteChildren: MerchantCheckoutRouteRouteChildren = {
  MerchantCheckoutIndexRoute: MerchantCheckoutIndexRoute,
}

const MerchantCheckoutRouteRouteWithChildren =
  MerchantCheckoutRouteRoute._addFileChildren(
    MerchantCheckoutRouteRouteChildren,
  )

interface MerchantOfferRouteRouteChildren {
  MerchantOfferIndexRoute: typeof MerchantOfferIndexRoute
}

const MerchantOfferRouteRouteChildren: MerchantOfferRouteRouteChildren = {
  MerchantOfferIndexRoute: MerchantOfferIndexRoute,
}

const MerchantOfferRouteRouteWithChildren =
  MerchantOfferRouteRoute._addFileChildren(MerchantOfferRouteRouteChildren)

interface MerchantRouteRouteChildren {
  MerchantPortalRouteRoute: typeof MerchantPortalRouteRouteWithChildren
  MerchantCheckoutRouteRoute: typeof MerchantCheckoutRouteRouteWithChildren
  MerchantOfferRouteRoute: typeof MerchantOfferRouteRouteWithChildren
}

const MerchantRouteRouteChildren: MerchantRouteRouteChildren = {
  MerchantPortalRouteRoute: MerchantPortalRouteRouteWithChildren,
  MerchantCheckoutRouteRoute: MerchantCheckoutRouteRouteWithChildren,
  MerchantOfferRouteRoute: MerchantOfferRouteRouteWithChildren,
}

const MerchantRouteRouteWithChildren = MerchantRouteRoute._addFileChildren(
  MerchantRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof MerchantPortalRouteRouteWithChildren
  '/checkout': typeof MerchantCheckoutRouteRouteWithChildren
  '/offer': typeof MerchantOfferRouteRouteWithChildren
  '/business': typeof MerchantPortalBusinessRoute
  '/documents': typeof MerchantPortalDocumentsRoute
  '/drawfunds': typeof MerchantPortalDrawfundsRoute
  '/instantfunding': typeof MerchantPortalInstantfundingRoute
  '/payment': typeof MerchantPortalPaymentRoute
  '/profile': typeof MerchantPortalProfileRoute
  '/renewal': typeof MerchantPortalRenewalRoute
  '/': typeof MerchantPortalIndexRoute
  '/checkout/': typeof MerchantCheckoutIndexRoute
  '/offer/': typeof MerchantOfferIndexRoute
}

export interface FileRoutesByTo {
  '': typeof MerchantRouteRouteWithChildren
  '/business': typeof MerchantPortalBusinessRoute
  '/documents': typeof MerchantPortalDocumentsRoute
  '/drawfunds': typeof MerchantPortalDrawfundsRoute
  '/instantfunding': typeof MerchantPortalInstantfundingRoute
  '/payment': typeof MerchantPortalPaymentRoute
  '/profile': typeof MerchantPortalProfileRoute
  '/renewal': typeof MerchantPortalRenewalRoute
  '/': typeof MerchantPortalIndexRoute
  '/checkout': typeof MerchantCheckoutIndexRoute
  '/offer': typeof MerchantOfferIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_merchant': typeof MerchantRouteRouteWithChildren
  '/_merchant/_portal': typeof MerchantPortalRouteRouteWithChildren
  '/_merchant/checkout': typeof MerchantCheckoutRouteRouteWithChildren
  '/_merchant/offer': typeof MerchantOfferRouteRouteWithChildren
  '/_merchant/_portal/business': typeof MerchantPortalBusinessRoute
  '/_merchant/_portal/documents': typeof MerchantPortalDocumentsRoute
  '/_merchant/_portal/drawfunds': typeof MerchantPortalDrawfundsRoute
  '/_merchant/_portal/instantfunding': typeof MerchantPortalInstantfundingRoute
  '/_merchant/_portal/payment': typeof MerchantPortalPaymentRoute
  '/_merchant/_portal/profile': typeof MerchantPortalProfileRoute
  '/_merchant/_portal/renewal': typeof MerchantPortalRenewalRoute
  '/_merchant/_portal/': typeof MerchantPortalIndexRoute
  '/_merchant/checkout/': typeof MerchantCheckoutIndexRoute
  '/_merchant/offer/': typeof MerchantOfferIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/checkout'
    | '/offer'
    | '/business'
    | '/documents'
    | '/drawfunds'
    | '/instantfunding'
    | '/payment'
    | '/profile'
    | '/renewal'
    | '/'
    | '/checkout/'
    | '/offer/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/business'
    | '/documents'
    | '/drawfunds'
    | '/instantfunding'
    | '/payment'
    | '/profile'
    | '/renewal'
    | '/'
    | '/checkout'
    | '/offer'
  id:
    | '__root__'
    | '/_merchant'
    | '/_merchant/_portal'
    | '/_merchant/checkout'
    | '/_merchant/offer'
    | '/_merchant/_portal/business'
    | '/_merchant/_portal/documents'
    | '/_merchant/_portal/drawfunds'
    | '/_merchant/_portal/instantfunding'
    | '/_merchant/_portal/payment'
    | '/_merchant/_portal/profile'
    | '/_merchant/_portal/renewal'
    | '/_merchant/_portal/'
    | '/_merchant/checkout/'
    | '/_merchant/offer/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  MerchantRouteRoute: typeof MerchantRouteRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  MerchantRouteRoute: MerchantRouteRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_merchant"
      ]
    },
    "/_merchant": {
      "filePath": "_merchant/route.tsx",
      "children": [
        "/_merchant/_portal",
        "/_merchant/checkout",
        "/_merchant/offer"
      ]
    },
    "/_merchant/_portal": {
      "filePath": "_merchant/_portal/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/_portal/business",
        "/_merchant/_portal/documents",
        "/_merchant/_portal/drawfunds",
        "/_merchant/_portal/instantfunding",
        "/_merchant/_portal/payment",
        "/_merchant/_portal/profile",
        "/_merchant/_portal/renewal",
        "/_merchant/_portal/"
      ]
    },
    "/_merchant/checkout": {
      "filePath": "_merchant/checkout/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/checkout/"
      ]
    },
    "/_merchant/offer": {
      "filePath": "_merchant/offer/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/offer/"
      ]
    },
    "/_merchant/_portal/business": {
      "filePath": "_merchant/_portal/business.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/documents": {
      "filePath": "_merchant/_portal/documents.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/drawfunds": {
      "filePath": "_merchant/_portal/drawfunds.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/instantfunding": {
      "filePath": "_merchant/_portal/instantfunding.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/payment": {
      "filePath": "_merchant/_portal/payment.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/profile": {
      "filePath": "_merchant/_portal/profile.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/renewal": {
      "filePath": "_merchant/_portal/renewal.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/": {
      "filePath": "_merchant/_portal/index.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/checkout/": {
      "filePath": "_merchant/checkout/index.tsx",
      "parent": "/_merchant/checkout"
    },
    "/_merchant/offer/": {
      "filePath": "_merchant/offer/index.tsx",
      "parent": "/_merchant/offer"
    }
  }
}
ROUTE_MANIFEST_END */
