import { Application } from 'merchant-api/types';
import useSWR from 'swr';

export interface UseApplicationProps {
  isLoadingApplication: boolean;
  applicationData: Application;
  isErrorApplication: any;
  opportunityId: string;
  applicationId: string;
  partyId: string;
  isPartnerChannel: Boolean;
}

export function useApplication(): UseApplicationProps {
  const apiUrl = import.meta.env['VITE_BUTTERFLY_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v3/orchestration/current_application`, {revalidateOnMount: true})
  return {
    applicationData: data,
    opportunityId: data?.metadata?.sfOpportunityId,
    applicationId: data?.id,
    partyId: data?.sourcePartyId,
    isLoadingApplication: isLoading,
    isErrorApplication: error,
    isPartnerChannel: data?.metadata?.channelCode === 'PARTNER_ISO'
  }
}


export default useApplication;
