import FileUpload from './FileUpload';
import { useFormContext } from 'react-hook-form';
import { getError } from './FormHelpers';
import React, { useEffect } from 'react';

import { Form } from 'react-bootstrap';
import { useWizardFormContext } from '@odmonorepo/core-components';

interface FormInputProps extends React.ComponentProps<typeof Form.Control> {
  name?: string;
  required?: boolean;
  label?: string;
  useYupErrors?: boolean;
}

export const FormFileUpload = ({name = '', required = true, label, useYupErrors = false, ...props}: FormInputProps) => {
  const { register, formState, clearErrors } = useFormContext() || { register: () => {}, formState: {} as any};
  const onInput = (e: any) => e.target.value && clearErrors(name);
  const { formName } = useWizardFormContext();

  return (
    <FileUpload
      onInput={onInput}
      required={required}
      {...(register(name as any) || {})}
      isInvalid={!!getError(name)}
      error={useYupErrors ? getError(name)?.message : undefined }
      label={label}
      form={formName || ''}
      {...props}
    />
  );
};


export default FormFileUpload;
