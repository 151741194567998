import { Col, Collapse, Row, Stack } from 'react-bootstrap';
import {
  Card,
  FormInput,
  FormMoneyInput,
  FormRadio,
  FormSelect,
  FormattedMessageDiv,
} from '@odmonorepo/core-components';
import { makeOptions } from '@odmonorepo/odfunctions';
import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';

export const BusinessDetailsSchema = yup.object().shape({
  business: yup.object().shape({
    legalEntityType: yup.string().required(),
    shortTermFinancing: yup.boolean(),
    lenderName: yup
      .string()
      .when('shortTermFinancing', {
        is: true,
        then: (field) => field.required(),
      }),
    remaining: yup
      .string()
      .when('shortTermFinancing', {
        is: true,
        then: (field) => field.required(),
      }),
  }),
  grossAnnualRevenue: yup.number().required(),
  averageBankBalance: yup.number().required(),
});

export const BusinessDetails = () => {
  const { watch } = useFormContext();
  const watchSTFin = watch('business.shortTermFinancing') === 'true';

  return (
    <Card>
      <Stack gap={3}>
        <FormattedMessageDiv
          className="fw-bold"
          id={'renewalApp.BusinessDetails'}
        />
        <FormSelect
          name="business.legalEntityType"
          options={makeOptions('business.legalEntityType', 7)}
        />
        <FormRadio
          name="business.shortTermFinancing"
          options={['true', 'false']}
        />
        <Collapse in={watchSTFin}>
          <Row xs={1} md={2} className="g-3">
            <Col>
              <FormInput name="business.lenderName" />
            </Col>
            <Col>
              <FormMoneyInput name="business.remaining" />
            </Col>
          </Row>
        </Collapse>
        <FormMoneyInput name="grossAnnualRevenue" />
        <FormMoneyInput name="averageBankBalance" />
      </Stack>
    </Card>
  );
};

export default BusinessDetails;
